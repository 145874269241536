<template>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex>
            <v-form>
              <div class="text-xs-center">
                <v-btn type="submit" class="primary" to="/" autofocus>
                  <v-icon>fa-home</v-icon>
                  <span class="pl-2">Take me home, please...</span>
                </v-btn>
              </div>

              <v-timeline>
                <v-timeline-item large color="error">
                  <template v-slot:opposite>
                    <code>sudo ls -lar /mnt/internet --mode:quantum-search | grep '{{$route.path}}' </code>
                  </template>
                  <v-card class="elevation-2 error">
                    <v-card-text class="display-2 text-xs-right">
                      404 not found
                    </v-card-text>
                  </v-card>
                </v-timeline-item>

                <v-timeline-item large color="warning">
                  <template v-slot:opposite>
                    <code>sudo astu --connect:cloud --force --find '{{$route.path}}'</code>
                  </template>
                  <v-card class="elevation-2 warning">
                    <v-card-text>
                      404 not found
                    </v-card-text>
                  </v-card>
                </v-timeline-item>

                <v-timeline-item large>
                  <template v-slot:opposite>
                    <code>astu local --find '{{$route.path}}'</code>
                  </template>
                  <v-card class="elevation-2">
                    <v-card-text class="text-xs-right">
                      404 not found
                    </v-card-text>
                  </v-card>
                </v-timeline-item>
              </v-timeline>
            </v-form>
          </v-flex>
        </v-layout>
      </v-container>
</template>
<script>
export default {
  data: () => ({
    timeline: [{
      opposite: 'Oh, lets find this page',
      text: 'astu'
    }]
  })
}
</script>
